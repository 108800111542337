import { Form } from '@unform/web';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { endOfMonth, format, startOfDay, startOfMonth } from 'date-fns';
import { endOfDay } from 'date-fns/esm';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import MenuPopUp from '../../../components/MenuPopPup';
import { Dialog as ModalNfeCancel } from '../../../components/Modal';
import useToastContext from '../../../hooks/toast';
import api, { apiTS } from '../../../services/api';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import OrderHeaderButtons from '../../order/header-buttons';
import { NfeCCeForm } from '../cce';
import INfeDTO from '../dtos/INfeDTO';
import FastFormDownloadNfeXml from '../fast-form-download-nfe-xml';
import statusOpts from '../types/statusOpts';
import formatCurrency from '../../../utils/FormatCurrency';
import messageRequestError from '../../../utils/messageRequestError';

const NfeList = (): React.ReactElement => {
  /** hooks */
  const navigate = useHistory();
  const toast = useToastContext();

  /** const */
  const hoje = new Date();

  /** states */
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const [formCCeVisible, setFormCCeVisible] = useState<boolean>(false);

  const [nfeId, setNfeId] = useState<string>('');

  const [busca, setBusca] = useState<string>('');
  const [dateStart, setDateStart] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [dateEnd, setDateEnd] = useState<Date | Date[] | undefined>(
    endOfMonth(hoje),
  );
  const [status, setStatus] = useState<string>('');
  const [usuario, setUsuario] = useState<string>('');
  const [usuarioOpts, setUsuarioOpts] = useState<IOptionsDTO[]>([]);
  const [pedido, setPedido] = useState<number>();

  const [nfes, setNfes] = useState<INfeDTO[]>([]);
  const [nfe, setNfe] = useState<INfeDTO>();

  const [justificativa, setJustificativa] = useState<string>('');

  const [tableFilter, setTableFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    pedido_tipo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    pedido_status: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  });

  const [filtroGlobal, setFiltroGlobal] = useState<string>('');

  const [modalCancelNfeVisible, setModalCancelNfeVisible] =
    useState<boolean>(false);

  const [modalDownloadXmlVisible, setModalDownloadXmlVisible] =
    useState<boolean>(false);

  /** functions */
  const handleSearchNfe = async () => {
    try {
      setIsLoad(true);

      const { data } = await api.get(`/nfe/search`, {
        params: {
          search: busca,
          date_a: startOfDay(dateStart as Date),
          date_b: endOfDay(dateEnd as Date),
          status: status,
          user_id: usuario,
          pedido: pedido,
        },
      });

      if (data.length > 0) {
        setNfes(data);
      } else {
        toast(
          'warn',
          'Alerta',
          'Nenhuma nfe foi encontrada com os parâmetros informados!',
          6000,
        );
        setNfes([]);
      }
    } catch (e: any) {
      toast('warn', 'Alerta', 'Falha ao buscar Nfes', 10000);
    } finally {
      setIsLoad(false);
    }
  };

  const checkCancellationStatus = async (id: string) => {
    setIsLoad(true);

    await api
      .get(`/nfe/cancel/status/${id}`)
      .then((res: AxiosResponse) => {
        if (res.data) {
          toast('success', 'Sucesso', 'Nota fiscal cancelada com sucesso!');
        }
      })
      .catch((err: AxiosError) => {
        toast('error', 'Erro', messageRequestError(err));
      })
      .finally(async () => {
        await handleSearchNfe();
        setIsLoad(false);
      });
  };

  const onGlobalFilterChange = (value: any) => {
    const filters = { ...tableFilter };
    filters['global'].value = value;

    setTableFilter(filters);
  };

  /** renders */
  const tableHeader = () => {
    const value = tableFilter['global'] ? tableFilter['global'].value : '';
    return (
      <Form
        ref={null}
        onSubmit={() => null}
        placeholder={''}
        onPointerEnterCapture={null}
        onPointerLeaveCapture={null}
      >
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <div className="flex align-items-center flex-wrap">
            <div className="flex align-items-center mr-4 mb-2">
              <h5 className="m-0 mr-3">Autorizadas</h5>
              <span className={`product-badge status-instock `}>
                {autorizadas}
              </span>
            </div>
            <div className="flex align-items-center mr-4 mb-2">
              <h5 className="m-0 mr-3">Aguardando</h5>
              <span className={`customer-badge status-new `}>{aguardando}</span>
            </div>
            <div className="flex align-items-center mr-4 mb-2">
              <h5 className="m-0 mr-3">Rejeitadas</h5>
              <span className={`product-badge status-outofstock`}>
                {rejeitadas}
              </span>
            </div>
            <div className="flex align-items-center mr-4 mb-2">
              <h5 className="m-0 mr-3">Denegadas</h5>
              <span className={`product-badge `}>{denegadas}</span>
            </div>
            <div className="flex align-items-center mr-4 mb-2">
              <h5 className="m-0 mr-3">Canceladas</h5>
              <span className={`product-badge status-outofstock`}>
                {canceladas}
              </span>
            </div>
            <div className="flex align-items-center mr-4 mb-2">
              <h5 className="m-0 mr-3">Qnt. Emitida</h5>
              <span className={`customer-badge status-new `}>{emitidas}</span>
            </div>
          </div>
          <span className="block mt-2 md:mt-0 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              name="search"
              type="search"
              value={value || ''}
              onChange={e => onGlobalFilterChange(e.currentTarget.value)}
              placeholder="Buscar..."
              autoComplete="off"
            />
          </span>
        </div>
      </Form>
    );
  };

  const renderColumnPosition = (rowData: any, attr: any) => {
    return <>{attr.rowIndex + 1}</>;
  };

  const renderColumnDate = (rowData: any) => {
    return <span>{format(new Date(rowData.emissao), 'dd/MM/yy')}</span>;
  };

  const renderColumnDecimal = (rowData: any) => {
    return (
      <span>
        {new Intl.NumberFormat('pt-BR', {
          currency: 'BRL',
          style: 'currency',
        }).format(rowData.valor)}
      </span>
    );
  };

  const renderColumnUser = (rowData: INfeDTO) => {
    return <span>{rowData.user_name.slice(0, 12)}</span>;
  };

  const renderCollumnStatus = (rowData: any) => {
    return (
      <>
        {rowData.status === 'CONCLUIDO' && (
          <span className={`product-badge status-instock mx-1`}>CONCLUÍDO</span>
        )}

        {rowData.status === 'PROCESSANDO' && (
          <span className={`customer-badge status-new mx-1`}>PROCESSANDO</span>
        )}

        {rowData.status === 'AGUARDANDO_CANCELAMENTO' && (
          <span className={`customer-badge status-new mx-1`}>PROCESSANDO</span>
        )}

        {rowData.status === 'CANCELADO' && (
          <span className={`product-badge status-outofstock mx-1`}>
            CANCELADA
          </span>
        )}

        {rowData.status === 'REJEITADO' && (
          <>
            <Button
              className="product-badge status-outofstock mx-1"
              tooltip={rowData.ts_id}
            >
              REJEITADA
            </Button>
            <br />
            {rowData.ts_id}
          </>
        )}

        {rowData.status === 'DENEGADO' && (
          <>
            <Button
              className="product-badge status-outofstock mx-1"
              tooltip={rowData.ts_id}
            >
              DENEGADA
            </Button>
            <br />
            {rowData.ts_id}
          </>
        )}

        {rowData.status === 'EXCEPTION' && (
          <>
            <Button
              className="product-badge status-outofstock mx-1"
              tooltip={rowData.ts_id}
            >
              REJEITADO
            </Button>
            <br />
            {rowData.ts_id}
          </>
        )}
      </>
    );
  };

  const sendNfe = async (id: string) => {
    try {
      setIsLoad(true);

      const res = await api.post(`/nfe/emit/${id}`);

      if (res.status === 200)
        toast('success', 'Sucesso', 'Nota fiscal enviada para a sefaz!', 10000);
    } catch (e: any) {
      toast('error', 'Erro', e.response.data.error, 10000);
    } finally {
      setIsLoad(false);
    }
  };

  const refreshNfe = async (id: string) => {
    const nfe = nfes.find(n => n.id === id);

    const nfeData = `NF-e ${
      nfe?.order_num !== null ? `PED ${nfe?.order_num}` : `OS ${nfe.os_num}`
    } - ${nfe?.person_name}${
      nfe?.valor ? ` - ${formatCurrency(nfe.valor)}` : ''
    }`;

    try {
      setIsLoad(true);

      const res = await api.get(`/nfe/status/${id}`);

      if (res.status === 200) {
        toast(
          'success',
          `Sucesso`,
          `A ${nfeData}. Status da nota fiscal foi atualizado!`,
          10000,
        );
      }
    } catch (e: any) {
      if (
        e.response.data.error &&
        e.response.data.error.includes('ainda está em processamento')
      ) {
        toast('info', `Processamento`, e.response.data.error, 10000);
      } else {
        toast('error', `Erro`, e.response.data.error, 10000);
      }
    } finally {
      handleSearchNfe();
      setIsLoad(false);
    }
  };

  const downloadPdf = async (url: string) => {
    const headers = {
      'x-api-key': `${
        process.env.REACT_APP_OPERATION_TYPE === 'HOM'
          ? process.env.REACT_APP_TS_TOKEN_TEST
          : process.env.REACT_APP_TS_TOKEN_NEXT
      }`,
    };
    await axios
      .get(url, {
        headers,
        responseType: 'blob',
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'wg-nfe';
        a.click();
        window.open(url);
      })
      .catch(() => {
        toast('error', 'Error', 'Erro ao exibir nfe!', 10000);
      });
  };

  const downloadXml = async (url: string) => {
    const headers = {
      'x-api-key': `${
        process.env.REACT_APP_OPERATION_TYPE === 'HOM'
          ? process.env.REACT_APP_TS_TOKEN_TEST
          : process.env.REACT_APP_TS_TOKEN_NEXT
      }`,
    };
    await axios
      .get(url, {
        headers,
        responseType: 'blob',
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'wg-nfe-xml';
        a.click();
        window.open(url);
      })
      .catch(() => {
        toast('error', 'Error', 'Erro ao exibir nfe!', 10000);
      });
  };

  const printNfe = async (url: string) => {
    const headers = {
      'x-api-key': `${
        process.env.REACT_APP_OPERATION_TYPE === 'HOM'
          ? process.env.REACT_APP_TS_TOKEN_TEST
          : process.env.REACT_APP_TS_TOKEN_NEXT
      }`,
    };
    await axios
      .get(url, {
        headers,
        responseType: 'blob',
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'wg-nfe';
        a.click();
        window.open(url);
      })
      .catch(() => toast('error', 'Error', 'Erro ao exibir nfe!', 10000));
  };

  const printCCe = async (id: string) => {
    return null;
  };

  const cancelNfe = async () => {
    try {
      setIsLoad(true);

      const res = await api.post(`/nfe/cancel/${nfe?.id}`, { justificativa });

      if (res.status === 200) {
        toast('info', 'Processando', 'Cancelamento em processamento.', 10000);
        setJustificativa('');
      }

      setModalCancelNfeVisible(false);
      await handleSearchNfe();
    } catch (error: any) {
      toast('error', 'Error', error.response?.data?.error, 10000);
    } finally {
      setIsLoad(false);
    }
  };

  const mountMenu = (rowData: INfeDTO) => {
    const items: any[] = [];

    if (rowData.status === 'PROCESSANDO') {
      items.push({
        label: 'Enviar Nfe',
        icon: 'pi pi-fw pi-cloud-upload',
        command: () => sendNfe(rowData.id),
      });
    }

    if (rowData.status === 'AGUARDANDO_CANCELAMENTO') {
      items.push({
        label: 'Atualizar status',
        icon: 'pi pi-fw pi-refresh',
        command: () => checkCancellationStatus(rowData.id),
      });
    }

    if (
      rowData.status !== 'CONCLUIDO' &&
      rowData.status !== 'AGUARDANDO_CANCELAMENTO'
    ) {
      items.push({
        label: 'Atualizar status',
        icon: 'pi pi-fw pi-refresh',
        command: () => refreshNfe(rowData.id),
      });

      if (rowData.status === 'REJEITADO') {
        items.push({
          label: 'Baixa Xml',
          icon: 'pi pi-fw pi-code',
          command: () => downloadXml(rowData.url_xml),
        });
      }
    }

    if (rowData.status === 'CANCELADO') {
      items.push(
        {
          label: 'Baixa Xml',
          icon: 'pi pi-fw pi-code',
          command: () => downloadXml(rowData.url_xml),
        },
        {
          label: 'Baixar Nfe',
          icon: 'pi pi-fw pi-file-pdf',
          command: () => downloadPdf(rowData.url_pdf),
        },
        {
          label: 'Imprimir Nfe',
          icon: 'pi pi-fw pi-print',
          command: () => printNfe(rowData.url_pdf),
        },
      );
    }

    if (rowData.status === 'CONCLUIDO') {
      items.push(
        {
          label: 'Atualizar status',
          icon: 'pi pi-fw pi-refresh',
          command: () => refreshNfe(rowData.id),
        },
        {
          label: 'Baixar Nfe',
          icon: 'pi pi-fw pi-file-pdf',
          command: () => downloadPdf(rowData.url_pdf),
        },
        {
          label: 'Baixa Xml',
          icon: 'pi pi-fw pi-code',
          command: () => downloadXml(rowData.url_xml),
        },
        {
          label: 'Imprimir Nfe',
          icon: 'pi pi-fw pi-print',
          command: () => printNfe(rowData.url_pdf),
        },
        {
          label: 'Cancelar Nfe',
          icon: 'pi pi-fw pi-times',
          command: () => {
            setModalCancelNfeVisible(true);
          },
        },
        {
          label: 'Carta correção CCe',
          icon: 'fa-solid fa-pen-to-square',
          command: async () => {
            setNfeId(rowData.id);
            setFormCCeVisible(true);
          },
        },
      );
    }

    return items;
  };

  const renderCollumnMenu = (rowData: INfeDTO) => {
    const mountOpts = mountMenu(rowData);

    return (
      <>
        <MenuPopUp model={mountOpts} rowData={rowData} setData={setNfe} />
      </>
    );
  };

  const renderColumnOrderOS = (d: INfeDTO) => {
    if (d.order_id) {
      return <>{d.order_num}</>;
    } else {
      return <>{d.os_num}</>;
    }
  };

  const [autorizadas, setAutorizadas] = useState<number>(0);
  const [canceladas, setCanceladas] = useState<number>(0);
  const [aguardando, setAguardando] = useState<number>(0);
  const [rejeitadas, setRejeitadas] = useState<number>(0);
  const [denegadas, setDenegadas] = useState<number>(0);
  const [emitidas, setEmitidas] = useState<number>(0);

  /** useEffect */
  useEffect(() => {
    const summary = nfes.reduce(
      (acc, item) => {
        if (item.status === 'CONCLUIDO') acc.ttAutorizadas++;
        else if (item.status === 'PROCESSANDO') acc.ttAguardando++;
        else if (item.status === 'REJEITADO') acc.ttRejeitadas++;
        else if (item.status === 'DENEGADO') acc.ttDenegadas++;
        else if (item.status === 'CANCELADO') acc.ttCanceladas++;

        acc.ttEmitidas++;

        return acc;
      },
      {
        ttAutorizadas: 0,
        ttCanceladas: 0,
        ttAguardando: 0,
        ttRejeitadas: 0,
        ttDenegadas: 0,
        ttEmitidas: 0,
      },
    );

    setAutorizadas(summary.ttAutorizadas);
    setCanceladas(summary.ttCanceladas);
    setAguardando(summary.ttAguardando);
    setRejeitadas(summary.ttRejeitadas);
    setDenegadas(summary.ttDenegadas);
    setEmitidas(summary.ttEmitidas);
  }, [nfes]);

  useEffect(() => {
    api
      .get('/nfe/search', {
        params: {
          search: busca,
          date_a: startOfDay(dateStart as Date),
          date_b: endOfDay(dateEnd as Date),
          status: status,
          user_id: usuario,
          pedido: pedido,
        },
      })
      .then(({ data }) => {
        if (data.length > 0) {
          setNfes(data);
        }
      })
      .finally(() => setIsLoad(false));

    api
      .post(`/users/list`, {
        pagination: {
          page: 1,
          perPage: 1000,
          status: ['A', 'C'],
        },
      })
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        if (opts.length > 0) {
          setUsuarioOpts([{ label: 'Todos', value: '' }, ...opts]);
        }
      });
  }, []);

  return (
    <div className="grid">
      <div className="col-12">
        <OrderHeaderButtons />
        <div className="card mb-2 pb-2">
          <Form
            ref={null}
            onSubmit={() => null}
            placeholder={''}
            onPointerEnterCapture={null}
            onPointerLeaveCapture={null}
          >
            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-6">
                <label htmlFor="search">Busca inteligente</label>
                <InputText
                  name="search"
                  type="search"
                  value={busca}
                  onChange={e => setBusca(e.currentTarget.value)}
                  placeholder="Ex: João da silva..."
                  min={3}
                  max={32}
                  autoComplete="off"
                />
              </div>

              <div className="field col-12 md:col-2">
                <label htmlFor="dateStart">Período inicial</label>
                <Calendar
                  name="dateStart"
                  value={dateStart}
                  onChange={e => setDateStart(e.value as Date)}
                  dateFormat="dd/mm/yy"
                  placeholder="00/00/00"
                  showIcon
                />
              </div>

              <div className="field col-12 md:col-2">
                <label htmlFor="dateEnd">Período final</label>
                <Calendar
                  name="dateEnd"
                  value={dateEnd}
                  onChange={e => setDateEnd(e.value as Date)}
                  dateFormat="dd/mm/yy"
                  placeholder="00/00/00"
                  showIcon
                />
              </div>

              <div className="field col-12 md:col-2 flex align-items-end">
                <Button
                  label="Buscar Nfe"
                  icon="pi pi-filter"
                  className="p-button p-button-success"
                  onClick={e => handleSearchNfe()}
                />
              </div>

              <div className="field col-12 md:col-4">
                <label htmlFor="status">Status</label>
                <Dropdown
                  value={status}
                  options={statusOpts}
                  onChange={e => {
                    setStatus(e.value);
                  }}
                  placeholder="Selecionar..."
                  optionLabel="label"
                />
              </div>

              <div className="field col-12 md:col-4">
                <label htmlFor="usuario">Usuário</label>
                <Dropdown
                  value={usuario}
                  options={usuarioOpts}
                  onChange={e => {
                    setUsuario(e.value);
                  }}
                  placeholder="Selecionar..."
                  optionLabel="label"
                />
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="pedido">Pedido</label>
                <InputText
                  name="pedido"
                  type="number"
                  value={pedido}
                  onChange={e => setPedido(Number(e.currentTarget.value))}
                  placeholder="Ex: 000"
                />
              </div>

              <div className="field col-12 md:col-1 flex align-items-end">
                <Button
                  icon="fa-solid fa-file-code"
                  label="XML"
                  onClick={() => setModalDownloadXmlVisible(true)}
                />
              </div>
            </div>
          </Form>
        </div>

        <div className="card mb-2 pb-2">
          <DataTable
            dataKey="id"
            header={tableHeader}
            value={nfes}
            selection={nfe}
            onSelectionChange={e => setNfe(e.value)}
            paginator
            rows={10}
            rowsPerPageOptions={[10, 20, 30]}
            size="small"
            loading={isLoad}
            loadingIcon="pi pi-spinner text-primary"
            filters={tableFilter}
            onFilter={e => setTableFilter(e.tableFilter)}
            globalFilter={filtroGlobal}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} Nfe"
            emptyMessage="Não existem Nfe..."
            responsiveLayout="scroll"
          >
            <Column header="#" body={renderColumnPosition}></Column>
            <Column
              header="Ped | O.S"
              body={d => renderColumnOrderOS(d)}
            ></Column>
            <Column header="Destinatário" field="person_name"></Column>
            <Column
              header="Emissão"
              field="emissao"
              body={e => renderColumnDate(e)}
            ></Column>
            <Column header="Sr." field="serie"></Column>
            <Column header="Número" field="numero"></Column>
            <Column
              header="Valor"
              field="valor"
              body={e => renderColumnDecimal(e)}
            ></Column>
            <Column
              header="Usuário"
              field="user_name"
              body={e => renderColumnUser(e)}
            ></Column>
            <Column
              header="Status"
              field="status"
              body={e => renderCollumnStatus(e)}
            ></Column>
            <Column header="*" body={e => renderCollumnMenu(e)}></Column>
          </DataTable>
        </div>
      </div>

      <ModalNfeCancel
        header="Cancelamento de Nfe"
        visible={modalCancelNfeVisible}
        onHide={() => setModalCancelNfeVisible(false)}
        style={{ width: '50vw' }}
      >
        <Form
          ref={null}
          onSubmit={() => null}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <label className="font-bold">Destinatário:</label>
              <span>&nbsp; &nbsp; {nfe?.person_name}</span>
            </div>
            <div className="field col-12 md:col-4">
              <label className="font-bold">Pedido:</label>
              <span>&nbsp; &nbsp; {nfe?.order_num || nfe?.os_num}</span>
            </div>
            <div className="field col-12 md:col-4">
              <label className="font-bold">Nfe Sr:</label>
              <span>&nbsp; &nbsp; {nfe?.serie}</span>
            </div>
            <div className="field col-12 md:col-4">
              <label className="font-bold">Nfe N:</label>
              <span>&nbsp; &nbsp; {nfe?.numero}</span>
            </div>
            <div className="field col-12 md:col-6">
              <label className="font-bold">Emissão</label>
              <span>
                &nbsp; &nbsp;{' '}
                {format(new Date(nfe?.emissao || 0), 'dd/MM/yyyy')}
              </span>
            </div>
            <div className="field col-12 md:col-4">
              <label className="font-bold">Valor</label>
              <span className="ml-3">
                {new Intl.NumberFormat('pt-BR', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(nfe?.valor || 0)}
              </span>
            </div>
            <div className="field col-12 md:col-12">
              <label className="font-bold">Justificativa</label>{' '}
              <span>{justificativa.length}/16</span>
              <InputTextArea
                placeholder="Sua justificativa..."
                name="justificativa"
                style={{ height: 100 }}
                onChange={e => setJustificativa(e.currentTarget.value)}
                autoFocus={true}
              ></InputTextArea>
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={isLoad}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={isLoad}
                disabled={justificativa.length <= 16}
                onClick={() => cancelNfe()}
              />
            </div>
          </div>
        </Form>
      </ModalNfeCancel>

      <NfeCCeForm
        id={nfeId}
        isOpen={formCCeVisible}
        onRequestClose={() => {
          setFormCCeVisible(false);
          setNfeId('');
        }}
      />

      <FastFormDownloadNfeXml
        isOpen={modalDownloadXmlVisible}
        onRequestClose={() => setModalDownloadXmlVisible(false)}
      />
    </div>
  );
};

const comparisonFn = function (prevProps: Window, nextProps: Window) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(NfeList, comparisonFn);
