export default function getTextColorBasedOnBackground(bgColor: string): string {
  // Remove o "#" do início, caso exista
  const color = bgColor.replace('#', '');

  // Converte o valor hexadecimal em RGB
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  // Calcula o brilho da cor (luminância relativa)
  // Fórmula baseada no padrão ITU-R BT.709
  const brightness = r * 0.299 + g * 0.587 + b * 0.114;

  // Define um limite de brilho para decidir a cor do texto
  return brightness > 150 ? '#000' : '#fff';
}
