import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import useToastContext from '../../../hooks/toast';
import { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { emptyBusinessDTO, IBusinessDTO } from '../dtos/IBusinessDTO';
import { ICity, emptyCity } from '../../person/dtos/IPerson';
import IOptionsDTO, { emptyOption } from '../dtos/IOptionsDTO';
import BusinessHeaderButtons from '../header-buttons';
import Progress from '../../../components/progress-bar';
import { Form } from '@unform/web';
import { TabPanel, TabView } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { EnumPessoa } from '../../../enum/EnumPessoa';
import { InputText } from '../../../components/Inputs/InputText';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import {
  qntFuncionariosOpts,
  regimeTributarioOpts,
  seguimentoEmpresaOpts,
  tamanhoEmpresaOpts,
  tipoPessoaOpts,
} from '../data/businessData';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputMask } from '../../../components/Inputs/InputMask';
import { Divider } from '../../../components/Divider';
import api, { apiCep, apiTS } from '../../../services/api';
import { AxiosError, AxiosResponse } from 'axios';
import messageRequestError from '../../../utils/messageRequestError';
import LimpaCpfCnpj from '../../../utils/LimpaCpfCnpj';
import UfsOpts from '../../person/types/UfsOpts';
import { cnpj } from 'cpf-cnpj-validator';
import getValidationErrors from '../../../utils/getErrorsValidation';
import { InputPassword } from '../../../components/Inputs/InputPassword';
import { FileUpload, FileUploadHandlerParam } from 'primereact/fileupload';
import { uploadImageEmptyTemplate } from '../../../components/upload/templates/upload-image-empty-template';
import stringIsNotNull from '../../../utils/strings/stringIsNotNull';
import Calendar from '../../../components/Inputs/InputCalendar';
import { uploadCertEmptyTemplate } from '../../../components/upload/templates/upload-cert-empty-template';

const BusinessCreate: React.FC = () => {
  const hoje = new Date();

  const navigate = useHistory();
  const toast = useToastContext();
  const formRef = useRef<FormHandles>(null);
  const uploadLogoRef = useRef<any>(null);
  const uploadCertRef = useRef<any>(null);

  const [isLoad, setIsLoad] = useState(false);
  const [data, setData] = useState<IBusinessDTO>(emptyBusinessDTO);
  const [city, setCity] = useState<ICity>(emptyCity);
  const [cities, setCities] = useState<ICity[]>([]);
  const [mainActivities, setMainActivities] = useState<IOptionsDTO[]>([]);
  const [cnaes, setCnaes] = useState<IOptionsDTO[]>([]);

  const [certPass, setCertPass] = useState<string>('');

  const [accept, setAccept] = useState(false);

  const loadCities = async () => {
    setIsLoad(true);
    await api
      .post(`/cities/list`, {
        pagination: {
          page: 1,
          perPage: 10000,
          status: ['A'],
        },
      })
      .then((res: AxiosResponse) => {
        if (res.data) {
          setCities(
            res.data.map((i: any) => {
              return {
                value: i.id,
                ibge: i.ibge,
                siafi: i.siafi,
                label: i.name,
                uf: i.uf.sigla,
              };
            }),
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const loadCitiesByUf = async (uf: string) => {
    setIsLoad(true);
    await api
      .get(`/cities/by/uf/${uf}`)
      .then((res: AxiosResponse) => {
        if (res.data) {
          setCities(
            res.data.map((i: any) => {
              return {
                value: i.id,
                ibge: i.ibge,
                siafi: i.siafi,
                label: i.name,
                uf: i.uf.sigla,
              };
            }),
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const loadMainActivities = async () => {
    setIsLoad(true);
    await api
      .get(`/main-activity`)
      .then((res: AxiosResponse) => {
        if (res.data) {
          setMainActivities(
            res.data.map((i: any) => {
              return {
                value: i.id,
                label: i.title,
              };
            }),
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const loadCnaes = async () => {
    setIsLoad(true);
    await api
      .post(`/rfb/cnae/all`)
      .then((res: AxiosResponse) => {
        if (res.data) {
          setCnaes(
            res.data.map((i: any) => {
              return {
                value: i.codigo,
                label: `${i.codigo} - ${i.descricao}`,
              };
            }),
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const loadAddress = async (cep: string) => {
    if (!cep || cep.length < 8) {
      toast('warn', 'Alerta', 'Informe um CEP válido!');
      return;
    }

    setIsLoad(true);
    const clearCep = LimpaCpfCnpj(cep);
    await apiCep
      .get(`/${clearCep}/json/`)
      .then((res: AxiosResponse) => {
        if (res.data) {
          const city = cities.find(i => i.ibge === res.data.ibge);
          setData({
            ...data,
            business: { ...data.business },
            config: { ...data.config },
            address: {
              ...data.address,
              post_code: cep,
              street:
                res.data.logradouro !== ''
                  ? res.data.logradouro
                  : data.address.street,
              complement:
                res.data.complemento !== ''
                  ? res.data.complemento
                  : data.address.complement,
              neighborhood:
                res.data.bairro !== ''
                  ? res.data.bairro
                  : data.address.neighborhood,
              city_id: city?.value || '',
              city: {
                ...data.address.city,
                uf: {
                  ...data.address.city?.uf,
                  sigla: city?.uf || '',
                },
              },
            },
          });

          toast(
            'info',
            'Endereço',
            'Dados de endereço encontrados com sucesso!',
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  useEffect(() => {
    if (LimpaCpfCnpj(data.address.post_code || '').length === 8) {
      loadAddress(data.address.post_code || '');
    }
  }, [data.address.post_code]);

  const handleSearchCnpj = async (doc: string) => {
    setIsLoad(true);
    if (!cnpj.isValid(doc)) {
      toast(
        'warn',
        'Alerta',
        `O CNPJ informado: ${cnpj.format(doc)} não é válido!`,
      );
    } else {
      const headers = { 'x-api-key': `${process.env.REACT_APP_TS_TOKEN_NEXT}` };

      await apiTS
        .get(`/cnpj/${LimpaCpfCnpj(doc)}`, { headers })
        .then((res: AxiosResponse) => {
          if (res.data) {
            if (res.data.situacao === 'BAIXADA')
              toast(
                'warn',
                'Alerta',
                `A empresa portadora do CNPJ ${doc} está baixada!`,
                6000,
              );

            if (res.data.status === 'ERROR') {
              toast('error', 'Erro', res.data.message);
              return;
            }

            setData({
              ...data,
              business: {
                ...data.business,
                name: res.data.razao_social,
                nickname:
                  res.data.fantasia && res.data.fantasia !== ''
                    ? res.data.fantasia
                    : res.data.razao_social.split(' ')[0],
                cpf_cnpj: res.data.cpf_cnpj,
                cnae:
                  res.data.atividades && res.data.atividades.length > 0
                    ? res.data.atividades.find(
                        (a: any) => a.tipo === 'principal',
                      ).codigo
                    : '',
                contact_name:
                  res.data.socios && res.data.socios.length > 0
                    ? res.data.socios[0].nome
                    : res.data.razao_social.split(' ')[0],
                contact_email: res.data.email,
                contact_phone: res.data.telefone,
                contact_mobile: res.data.telefone,
                valid_to_emit_nfce: false,
                valid_to_emit_nfe: false,
              },
              address: {
                ...data.address,
                post_code: res.data.endereco.cep,
                complement: res.data.complemento,
                street: res.data.endereco.logradouro,
                number: res.data.endereco.numero,
                neighborhood: res.data.endereco.bairro,
              },
              config: {
                prefeitura: {
                  login: '',
                  senha: '',
                },
                producao: false,
                rps: {
                  lote: 0,
                  businessConfigRpsNumeracoes: [],
                },
              },
            });

            toast('info', 'Dados', 'Dados carregados na receita com sucesso!');
          }
        })
        .catch((e: AxiosError) => {
          toast(
            'warn',
            'Falha',
            `Falha ao buscar os dados da receita, preencha manualmente ou tente novamente mais tarde: ${e.message}`,
          );
        })
        .finally(() => {
          setIsLoad(false);
        });
    }
  };

  const handleUploadImage = async (e: FileUploadHandlerParam) => {
    if (uploadLogoRef.current.files) {
      setIsLoad(true);
      const formData = new FormData();
      formData.append('img_url', uploadLogoRef.current.files[0]);
      await api
        .patch('/business/logo/upload', formData)
        .then((res: AxiosResponse) => {
          if (res.status === 201) {
            toast('success', 'Sucesso', 'Imagem enviada com sucesso.');
          }
        })
        .catch((e: AxiosError) => {
          toast('warn', 'Alerta', `Falha ao enviar a imagem: ${e.message}`);
        })
        .finally(() => {
          setIsLoad(false);
        });
    }
  };

  const handleUploadCert = async (e: FileUploadHandlerParam) => {
    if (!stringIsNotNull(certPass)) {
      toast('warn', 'Alerta', 'Por favor, informe a senha do certificado.');
      return;
    }

    if (uploadCertRef.current.files) {
      setIsLoad(true);
      const formData = new FormData();
      formData.append('cert_url', uploadCertRef.current.files[0]);
      formData.append('cert_pass', certPass);
      await api
        .patch('/business/cert/upload', formData)
        .then((res: AxiosResponse) => {
          if (res.status === 201) {
            toast('success', 'Sucesso', 'Certificado enviado com sucesso.');
            setCertPass('');
          }
        })
        .catch((e: AxiosError) => {
          toast('warn', 'Alerta', messageRequestError(e));
        })
        .finally(() => {
          setIsLoad(false);
        });
    }
  };

  const handleOpenModalContract = () => {};

  const clearForm = () => {
    setData(emptyBusinessDTO);
  };

  const handleSubmit = async (d: any) => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});

      const businessSchema = Yup.object({
        name: Yup.string()
          .min(3, 'Nome/Razão Social deve ter no mínimo 3 caracteres.')
          .max(64, 'Nome/Razão Social pode ter no máximo 64 caracteres.')
          .required('Informe Nome/Razão Social.'),
        nickname: Yup.string()
          .max(64, 'Apelido/Nome Fantasia pode ter no máximo 64 caracteres.')
          .required('Informe Apelido/Nome Fantasia.'),
        kind_business: Yup.string().required(
          'Pessoa Física/Jurídica é obrigatório.',
        ),
        cpf_cnpj: Yup.string()
          .max(18, 'CPF/CNPJ pode ter no máximo 18 caracteres.')
          .required('CPF/CNPJ deve ser informado!'),
        rg_ie: Yup.string().max(18, 'RG/IE pode ter no máximo 18 caracteres.'),
        insc_municipal: Yup.string().max(
          18,
          'Insc. Municipal pode ter no máximo 18 caracteres.',
        ),
        tax_free: Yup.string()
          .max(1, 'Isento pode ter no máximo 1 caractere.')
          .required('ISENTO deve ser marcado'),
        cnae: Yup.string().required('CNAE Principal deve ser informado'),
        main_activity_id: Yup.string().required(
          'Atividade principal deve ser informada.',
        ),
        regime_tributario: Yup.string().required(
          'Regime Tributário deve ser informado.',
        ),
        bussines_size: Yup.string().required(
          'Porte da empresa deve ser informado.',
        ),
        bussines_followup: Yup.string().required(
          'Seguimento deve ser informado.',
        ),
        employers_qnt: Yup.number().required(
          'Qnt. de funcionários deve ser informada',
        ),
        contact_name: Yup.string()
          .max(64, 'Nome do contato pode ter no máximo 64 caracteres.')
          .required('Nome do contato deve ser informado.'),
        contact_email: Yup.string().max(
          64,
          'Email pode ter no máximo 64 caracteres.',
        ),
        contact_phone: Yup.string().max(
          16,
          'Telefone fixo pode ter no máximo 16 caracteres',
        ),
        contact_mobile: Yup.string().max(
          16,
          'Telefone celular pode ter no máximo 16 caracteres',
        ),
        contact_site: Yup.string().max(
          128,
          'Site pode ter no máximo 128 caracteres.',
        ),
      });
      await businessSchema.validate(data.business, { abortEarly: false });

      const addressSchema = Yup.object({
        post_code: Yup.string().required('Por favor, informe o cep!'),
        street: Yup.string()
          .max(64, 'Rua pode ter no máximo 64 caracteres!')
          .required('Por favor, informe a rua!'),
        number: Yup.string()
          .max(9, 'Número pode ter no máximo 9 caracteres')
          .required('Por favor, informe o numero!'),
        neighborhood: Yup.string()
          .max(64, 'Bairro pode ter no máximo 32 caracteres.')
          .required('Por favor, informe o bairro'),
        complement: Yup.string().max(
          64,
          'Complemento pode ter no máximo 64 caracteres',
        ),
        city_id: Yup.string().uuid().required('Por favor, informe a cidade!'),
        city: Yup.object({
          uf: Yup.object({
            sigla: Yup.string(),
          }),
        }),
      });
      await addressSchema.validate(data.address, { abortEarly: false });

      delete data.address.city;
      delete data.business.logo_url;
      delete data.business.cert_url;
      delete data.business.cert_expiration;
      delete data.business.config_id;

      await api.post('/business', {
        data: {
          business: { ...data.business },
          address: { ...data.address },
          config: { ...data.config },
        },
      });

      toast('success', 'Sucesso', 'Registro salvo com sucesso!');

      navigate.push('/business/list');
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Erro', e.errors[0]);
      } else {
        toast('error', 'Erro', messageRequestError(e));
      }
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    Promise.all([loadCities(), loadMainActivities(), loadCnaes()]);
  }, []);

  return (
    <>
      <BusinessHeaderButtons />
      <Progress isLoad={isLoad} />
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        placeholder={''}
        onPointerEnterCapture={null}
        onPointerLeaveCapture={null}
        className="card mt-0 pt-0 pb-0"
      >
        <TabView>
          <TabPanel
            header={`${
              data.business.kind_business === EnumPessoa.FISICA
                ? 'Pessoa'
                : 'Empresa'
            }`}
            leftIcon={`fa-solid fa-${
              data.business.kind_business === EnumPessoa.FISICA
                ? 'user'
                : 'industry'
            } mr-2`}
            className="p-fluid grid formgrid"
          >
            <div className="field col-12 md:col-2">
              <label htmlFor="kind_business">Pessoa Física/Jurídica?</label>
              <InputDropDown
                options={tipoPessoaOpts}
                value={data.business.kind_business}
                name="kind_business"
                placeholder="Selecionar..."
                onChange={e => {
                  formRef.current?.setFieldError('kind_business', '');
                  setData({
                    ...data,
                    business: { ...data.business, kind_business: e.value },
                  });
                }}
              />
            </div>

            <div className="field col-12 md:col-3">
              <label htmlFor="cpf_cnpj">
                {data.business.kind_business === 'F' ? 'CPF*' : 'CNPJ*'}
              </label>
              {data.business.kind_business === 'J' && (
                <a
                  className="m-2"
                  onClick={() => handleSearchCnpj(data.business.cpf_cnpj || '')}
                >
                  <i className="fa-solid fa-search"></i>
                </a>
              )}
              <InputMask
                name="cpf_cnpj"
                value={data.business.cpf_cnpj}
                onChange={e => {
                  formRef.current?.setFieldError('cpf_cnpj', '');
                  setData({
                    ...data,
                    business: { ...data.business, cpf_cnpj: e.value },
                  });
                }}
                placeholder={
                  data.business.kind_business === 'F'
                    ? 'Ex.: 000.000.000-00'
                    : 'Ex.: 00.000.000/0000-00'
                }
                mask={
                  data.business.kind_business === 'F'
                    ? '999.999.999-99'
                    : '99.999.999/9999-99'
                }
                onComplete={e => {
                  if (data.business.kind_business === 'J') {
                    handleSearchCnpj(e.value || '');
                  }
                }}
                disabled={isLoad}
              />
            </div>

            <div className="field col-12 md:col-3">
              <label htmlFor="rg_ie">
                {data.business.kind_business === 'F'
                  ? 'RG*'
                  : 'Inscrição estadual*'}
              </label>
              {data.business.kind_business === 'J' && (
                <a
                  className="m-2"
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.sintegra.gov.br/"
                >
                  <i className="fa-solid fa-search"></i>
                </a>
              )}
              <InputText
                name="rg_ie"
                value={data.business.rg_ie}
                onChange={e => {
                  formRef.current?.setFieldError('rg_ie', '');
                  setData({
                    ...data,
                    business: {
                      ...data.business,
                      rg_ie: e.currentTarget.value,
                    },
                  });
                }}
                placeholder={
                  data.business.kind_business === 'F'
                    ? 'Ex.: 000.000.000'
                    : 'Ex.: 00.000.000.0'
                }
                keyfilter="int"
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="insc_municipal">Insc. Municipal</label>
              <InputText
                name="insc_municipal"
                value={data.business.insc_municipal}
                onChange={e => {
                  formRef.current?.setFieldError('insc_municipal', '');
                  setData({
                    ...data,
                    business: {
                      ...data.business,
                      insc_municipal: e.currentTarget.value,
                    },
                  });
                }}
                placeholder={'Ex.: 000.000.000'}
                keyfilter="int"
              />
            </div>

            <div className="field col-12 md:col-2 flex align-items-center justify-content-center">
              <InputSwitch
                name="tax_free"
                checked={data.business.tax_free}
                falseValue="N"
                trueValue="S"
                onChange={e => {
                  formRef.current?.setFieldError('tax_free', '');
                  setData({
                    ...data,
                    business: {
                      ...data.business,
                      tax_free: e.value === true ? 'S' : 'N',
                    },
                  });
                }}
                disabled={data.business.kind_business === 'F' ? true : false}
              />
              <label htmlFor="tax_free" className="ml-3">
                Isento?
              </label>
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="name">
                {data.business.kind_business === 'J'
                  ? 'Razão Social*'
                  : 'Nome*'}
              </label>
              <InputText
                name="name"
                value={data.business.name}
                onChange={e => {
                  formRef.current?.setFieldError('name', '');
                  setData({
                    ...data,
                    business: { ...data.business, name: e.currentTarget.value },
                  });
                }}
                placeholder={
                  data.business.kind_business === 'J'
                    ? `Ex.: Empresa Acme Ltda`
                    : 'Ex.: João Silva'
                }
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="nickname">
                {data.business.kind_business === 'J'
                  ? 'Nome Fantasia'
                  : 'Apelido'}
              </label>
              <InputText
                name="nickname"
                value={data.business.nickname}
                onChange={e => {
                  formRef.current?.setFieldError('nickname', '');
                  setData({
                    ...data,
                    business: {
                      ...data.business,
                      nickname: e.currentTarget.value,
                    },
                  });
                }}
                placeholder={
                  data.business.kind_business === 'J'
                    ? 'Ex.: Acme Ltda'
                    : 'Ex.: Joãozinho'
                }
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="cnae">CNAE Principal</label>
              <InputDropDown
                name="cnae"
                options={cnaes}
                value={data.business.cnae}
                onChange={e => {
                  formRef.current?.setFieldError('cnae', '');
                  setData({
                    ...data,
                    business: { ...data.business, cnae: e.value },
                  });
                }}
                placeholder={'Selecione...'}
                tooltip={
                  data.business.kind_business === 'F'
                    ? 'Somente pessoa júridica!'
                    : ''
                }
                tooltipOptions={{ position: 'top' }}
              />
            </div>

            <div className="field col-12 md:col-3">
              <label htmlFor="main_activity_id">Atividade principal</label>
              <InputDropDown
                options={mainActivities}
                value={data.business.main_activity_id}
                name="main_activity_id"
                placeholder="Selecionar..."
                onChange={e => {
                  formRef.current?.setFieldError('main_activityId', '');
                  setData({
                    ...data,
                    business: {
                      ...data.business,
                      main_activity_id: e.value,
                    },
                  });
                }}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="regime_tributario">Regime tributário*</label>
              <InputDropDown
                options={regimeTributarioOpts}
                value={data.business.regime_tributario}
                name="regime_tributario"
                placeholder="Selecionar..."
                onChange={e => {
                  formRef.current?.setFieldError('regime_tributario', '');
                  setData({
                    ...data,
                    business: {
                      ...data.business,
                      regime_tributario: e.value,
                    },
                  });
                }}
                disabled={data.business.kind_business === 'F'}
                className={`${
                  data.business.kind_business === 'F' ? 'surface-200' : ''
                }`}
              />
            </div>

            <div className="field col-12 md:col-3">
              <label htmlFor="bussines_size">Porte*</label>
              <InputDropDown
                options={tamanhoEmpresaOpts}
                value={data.business.bussines_size}
                name="bussines_size"
                placeholder="Selecionar..."
                onChange={e => {
                  formRef.current?.setFieldError('bussines_size', '');

                  setData({
                    ...data,
                    business: { ...data.business, bussines_size: e.value },
                  });
                }}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="bussines_followup">Seguimento*</label>
              <InputDropDown
                options={seguimentoEmpresaOpts}
                value={data.business.bussines_followup}
                name="bussines_followup"
                placeholder="Selecionar..."
                onChange={e => {
                  formRef.current?.setFieldError('bussines_followup', '');

                  setData({
                    ...data,
                    business: { ...data.business, bussines_followup: e.value },
                  });
                }}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="employers_qnt">Qnt. funcionários*</label>
              <InputDropDown
                options={qntFuncionariosOpts}
                value={data.business.employers_qnt}
                name="employers_qnt"
                placeholder="Selecionar..."
                onChange={e => {
                  formRef.current?.setFieldError('employers_qnt', '');

                  setData({
                    ...data,
                    business: { ...data.business, employers_qnt: e.value },
                  });
                }}
              />
            </div>

            <Divider align="left">
              <span className="p-tag">Endereço</span>
            </Divider>

            <div className="field col-12 md:col-3">
              <label htmlFor="post_code">Cep</label>
              <a
                className="m-2"
                target="_blank"
                onClick={() => {
                  loadAddress(data.address.post_code || '');
                }}
              >
                <i className="fa-solid fa-search"></i>
              </a>
              <InputMask
                name="post_code"
                mask="99.999-999"
                value={data.address.post_code}
                placeholder="Ex.: 00.000-000"
                onChange={e => {
                  formRef.current?.setFieldError('post_code', '');

                  setData({
                    ...data,
                    address: { ...data.address, post_code: e.value },
                  });
                }}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="street">Rua/Avenida</label>
              <InputText
                name="street"
                value={data.address.street}
                onChange={e =>
                  setData({
                    ...data,
                    address: { ...data.address, street: e.currentTarget.value },
                  })
                }
                placeholder={'Ex.: Rua da amizade...'}
              />
            </div>

            <div className="field col-12 md:col-3">
              <label htmlFor="street">Número/Apto</label>
              <InputText
                name="number"
                value={data.address.number}
                onChange={e =>
                  setData({
                    ...data,
                    address: { ...data.address, number: e.currentTarget.value },
                  })
                }
                placeholder={'Ex.: 0000'}
              />
            </div>

            <div className="field col-12 md:col-3">
              <label htmlFor="street">Complemento</label>
              <InputText
                name="complement"
                value={data.address.complement}
                onChange={e =>
                  setData({
                    ...data,
                    address: {
                      ...data.address,
                      complement: e.currentTarget.value,
                    },
                  })
                }
                placeholder={'Ex.: Qd 00 Lt 00'}
              />
            </div>

            <div className="field col-12 md:col-3">
              <label htmlFor="neighborhood">Bairro</label>
              <InputText
                name="neighborhood"
                value={data.address.neighborhood}
                onChange={e =>
                  setData({
                    ...data,
                    address: {
                      ...data.address,
                      neighborhood: e.currentTarget.value,
                    },
                  })
                }
                placeholder={'Ex.: Bairro da saudade'}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="uf">UF (Estado)</label>
              <InputDropDown
                name="uf"
                options={UfsOpts}
                optionLabel="value"
                value={data.address.city?.uf.sigla}
                onChange={e => {
                  setData({
                    ...data,
                    address: {
                      ...data.address,
                      city: {
                        ...data.address.city,
                        uf: {
                          ...data.address.city?.uf,
                          sigla: e.value,
                        },
                      },
                    },
                  });
                  loadCitiesByUf(e.value);
                }}
                placeholder="Selecionar..."
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="uf">Cidade</label>
              <InputDropDown
                filter
                options={cities}
                name="city_id"
                value={data.address.city_id}
                onChange={e => {
                  setData({
                    ...data,
                    address: { ...data.address, city_id: e.value },
                  });
                }}
                optionLabel="label"
                placeholder="Selecionar..."
                disabled={
                  data.address.city?.uf.sigla === undefined ? true : false
                }
                style={{
                  background:
                    data.address.city?.uf.sigla === ''
                      ? 'var(--surface-200)'
                      : '',
                }}
              />
            </div>

            <Divider align="left">
              <span className="p-tag">Contato</span>
            </Divider>

            <div className="field col-12 md:col-4">
              <label htmlFor="contact_name">Nome do contato</label>
              <InputText
                name="contact_name"
                value={data.business.contact_name}
                onChange={e =>
                  setData({
                    ...data,
                    business: {
                      ...data.business,
                      contact_name: e.currentTarget.value,
                    },
                  })
                }
                placeholder={'Ex.: Patrão da Silva'}
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="cnae">Telefone fixo</label>
              <InputMask
                name="contact_fixe"
                mask="(99) 9999-9999"
                value={data.business.contact_phone}
                onChange={e =>
                  setData({
                    ...data,
                    business: { ...data.business, contact_phone: e.value },
                  })
                }
                placeholder="Ex.: (00) 0000-0000"
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="contact_mobile">Telefone celular</label>
              <InputMask
                name="contact_mobile"
                mask="(99) 99999-9999"
                value={data.business.contact_mobile}
                onChange={e =>
                  setData({
                    ...data,
                    business: { ...data.business, contact_mobile: e.value },
                  })
                }
                placeholder="Ex.: (00) 00000-0000"
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="contact_email">E-Mail</label>
              <InputText
                name="contact_email"
                value={data.business.contact_email}
                onChange={e =>
                  setData({
                    ...data,
                    business: {
                      ...data.business,
                      contact_email: e.currentTarget.value,
                    },
                  })
                }
                placeholder={'Ex.: empresa@dominio.com.br'}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="cnacontact_sitee">Site</label>
              <InputText
                name="contact_site"
                value={data.business.contact_site}
                onChange={e =>
                  setData({
                    ...data,
                    business: {
                      ...data.business,
                      contact_site: e.currentTarget.value,
                    },
                  })
                }
                placeholder={'Ex.: www.empresa.com.br'}
              />
            </div>
          </TabPanel>
          <TabPanel
            header={'Configurações'}
            leftIcon="fa-solid fa-gears mr-2"
            className="p-fluid grid formgrid"
          >
            <Divider align="left">
              <span className="p-tag">Modo de emissão (Nfe, Nfce e Nfse)</span>
            </Divider>

            <div className="field col-12 md:col-2 flex align-items-center justify-content-left">
              <InputSwitch
                name="config.producao"
                checked={data.config.producao}
                falseValue="N"
                trueValue="S"
                onChange={e =>
                  setData({
                    ...data,
                    config: {
                      ...data.config,
                      producao: e.value,
                    },
                  })
                }
                disabled={data.business.kind_business === 'F' ? true : false}
              />

              <label htmlFor="config.producao" className="ml-3">
                Produção
              </label>
            </div>

            <Divider align="left">
              <span className="p-tag">Dados Nfse</span>
            </Divider>

            <div className="field col-12 md:col-2">
              <label htmlFor="prefeitura.login">Login</label>
              <InputText
                name="prefeitura.login"
                type="text"
                value={data.config.prefeitura?.login}
                onChange={e =>
                  setData({
                    ...data,
                    config: {
                      ...data.config,
                      prefeitura: {
                        ...data.config.prefeitura,
                        login: e.currentTarget.value,
                      },
                    },
                  })
                }
                placeholder={'Email'}
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="prefeitura.senha">Senha</label>
              <InputPassword
                name="prefeitura.senha"
                toggleMask={true}
                value={data.config.prefeitura?.senha}
                onChange={e =>
                  setData({
                    ...data,
                    config: {
                      ...data.config,
                      prefeitura: {
                        ...data.config.prefeitura,
                        senha: e.currentTarget.value,
                      },
                    },
                  })
                }
                placeholder={'Senha'}
              />
            </div>
          </TabPanel>

          <TabPanel
            header={'Logo'}
            leftIcon="fa-solid fa-image mr-2"
            className="p-fluid grid formgrid"
          >
            <FileUpload
              ref={uploadLogoRef}
              name="img_url"
              mode="advanced"
              url={'/business/logo/upload'}
              multiple={false}
              accept="image/*"
              maxFileSize={1000000}
              emptyTemplate={() =>
                uploadImageEmptyTemplate(data.business.logo_url || '')
              }
              className="field col-12 mx-0 px-0"
              chooseLabel="Escolher"
              uploadLabel="Enviar"
              cancelLabel="Cancelar"
              customUpload
              uploadHandler={e => handleUploadImage(e)}
            />
          </TabPanel>
          <TabPanel
            header={'Certificado'}
            leftIcon="fa-solid fa-id-card mr-2"
            className="p-fluid grid formgrid"
          >
            <FileUpload
              ref={uploadCertRef}
              name="cert_url"
              mode="advanced"
              url={'/business/cert/upload'}
              multiple={false}
              accept="application/x-pkcs12"
              maxFileSize={1000000}
              className="field col-12 mx-0 px-0"
              chooseLabel="Escolher"
              uploadLabel="Enviar"
              cancelLabel="Cancelar"
              customUpload
              uploadHandler={e => handleUploadCert(e)}
              emptyTemplate={() =>
                uploadCertEmptyTemplate(data.business.cert_url || '')
              }
            />

            <div className="field col-12 md:col-4 px-0">
              <label htmlFor="cert_pass">Senha</label>
              <InputPassword
                name="cert_pass"
                value={certPass}
                onChange={e => setCertPass(e.currentTarget.value)}
                placeholder="Ex.: 123456"
                toggleMask
                feedback={false}
              />
            </div>

            <div className="field col-4">
              <label htmlFor="cert_expiration">Data de vencimento</label>
              <Calendar
                name="cert_expiration"
                disabled
                value={new Date(data.business.cert_expiration || '')}
                placeholder="Ex.: 31/12/2024"
              />
            </div>
          </TabPanel>
        </TabView>

        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              onClick={e => clearForm()}
              className="p-button-danger"
              loading={isLoad}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Salvar"
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={isLoad}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default BusinessCreate;
